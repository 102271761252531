<template>
  <AssetsStyled>
    <PanelTableMolecule
      :isLoading="loading"
      :count="totalCount"
      :title="$tc('asset', 2) | capitalize"
      :columns="columns"
      :rows="tableData"
      :pageIndex="page + 1"
      :pageSize="perPage"
      :createRouteName="'createAsset'"
      @pageNumberChange="pageNumberChange($event)"
      @reload="reload"
    />
    <router-view @reload="reload" />
  </AssetsStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import get from 'lodash/get'
import { getRolesFromToken } from '@common/utils'
import { Edit2Icon, SearchIcon } from 'vue-feather-icons'

import ASSETS_CONNECTION_QUERY from '#/graphql/operations/assets/connectionQuery/assetsConnection.gql'

import PanelTableMolecule from '@/components/Atomic/Molecules/PanelTableMolecule'
import TableColumnSearch from '@/components/Atomic/Molecules/TableColumnSearch'
import EntityLinkAtom from '../Atomic/Atoms/EntityLinkAtom'

const AssetsStyled = styled('div')`
  margin: 1rem;
  .actions {
    cursor: pointer;
    color: ${p => p.theme.colors.primary};
    &:hover {
      color: ${props => props.theme.colors.white};
    }
  }
`

export default {
  components: {
    AssetsStyled,
    PanelTableMolecule,
  },
  data() {
    return {
      loading: true,
      totalCount: 0,
      models: [],
      errors: [],
      searchQuery: '',
      where: null,
      perPage: 10,
      page: 0,
    }
  },
  computed: {
    take() {
      return this.perPage
    },
    skip() {
      return this.page * this.perPage
    },
    userRoles() {
      return getRolesFromToken(this.$keycloak.token)
    },
    hasWritePermission() {
      return this.userRoles.includes('archon') || this.userRoles.includes('talpa-product')
    },
    editRoute() {
      if (this.hasWritePermission) {
        return 'asset'
      } else {
        return null
      }
    },
    createRoute() {
      if (this.hasWritePermission) {
        return 'createAsset'
      } else {
        return null
      }
    },
    columns() {
      const header = [
        {
          field: 'name',
          key: 'a',
          title: 'Name',
          align: 'left',
          filterCustom: {
            defaultVisible: false,
            render: ({ closeFn }) => {
              return (
                <TableColumnSearch
                  searchValue={this.searchQuery}
                  placeholder="Search Name"
                  on-valueChange={e => (this.searchQuery = e)}
                  on-cancel={() => this.searchCancel(closeFn)}
                  on-confirm={() => this.searchConfirm(closeFn)}
                />
              )
            },
            // custom filter icon
            filterIcon: () => {
              return <SearchIcon size="1.3x" />
            },
          },
          renderBodyCell: ({ row }) => {
            const { id, __typename, name } = row
            if (!id || !__typename || !name) {
              return `something missing`
            }
            return <EntityLinkAtom type={__typename} id={id} label={name} />
          },
        },
        { field: 'assetType', key: 'assetType', title: 'Asset Type', align: 'center' },
        {
          field: 'site',
          key: 'site',
          title: 'Site',
          align: 'center',
          width: '20%',
          renderBodyCell: ({ row }) => {
            if (!row?.site) {
              return `-`
            }
            const { id, __typename, name } = row.site
            if (!id || !__typename || !name) {
              return `something missing`
            }
            return <EntityLinkAtom type={__typename} id={id} label={name} />
          },
        },
        { field: 'status', key: 'status', title: 'Status', align: 'center', width: '8%' },
        {
          field: 'actions',
          key: 'i',
          title: 'Actions',
          width: '5%',
          center: 'left',
          renderBodyCell: ({ row }) => {
            return (
              <span class="actions">
                <div on-click={() => this.$router.push({ name: this.editRoute, params: { id: row?.rowKey } })}>
                  <Edit2Icon size="1.3x" />
                </div>
              </span>
            )
          },
        },
      ]
      return header
    },
    tableData() {
      const tableData = this.models.map(asset => {
        return {
          name: asset.name,
          assetType: asset.type?.name,
          site: asset.site,
          status: asset.disabled ? 'Disabled' : 'Active',
          rowKey: asset.id,
          id: asset.id,
          __typename: asset.__typename,
        }
      })
      return tableData
    },
  },
  methods: {
    nextPage() {
      this.page = this.page + 1
    },
    reload() {
      this.searchQuery = ''
      this.$apollo.queries.models.refetch()
    },
    pageNumberChange(pageIndex) {
      this.page = pageIndex - 1
    },
    searchCancel(closeFn) {
      this.searchQuery = ''
      closeFn()
    },
    searchConfirm(closeFn) {
      closeFn()
    },
  },
  watch: {
    searchQuery: {
      handler(s) {
        this.page = 0
        if (s) {
          this.where = {
            name: {
              contains: s,
            },
          }
        } else {
          this.where = undefined
        }
      },
    },
  },
  apollo: {
    models: {
      query: ASSETS_CONNECTION_QUERY,
      variables() {
        return {
          take: this.take,
          skip: this.skip,
          where: this.where,
        }
      },
      manual: true,
      result({ data, loading }) {
        this.loading = loading
        this.models = get(data, 'machines', [])
        this.totalCount = get(data, 'assetsConnectionTotal', 0)
      },
    },
  },
}
</script>
